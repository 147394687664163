.loginLayout {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0 auto;
  background: #eff1f7;
}
/* .logo{
  width: 250px;
  height: 150px;
  background:#ccc;
} */
.loginContainer {
  /* border-radius: 8px; */
  min-width: 360px;
  font-size: 14px;
  line-height: 1.5;
  /* color: rgba(0, 0, 0, 0.65); */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.titleText {
  font-size: 25px;
  font-weight: 500;
  color: #212121;
}
.desc {
  font-size: 17px;
  color: #919191;
}
