.menuBar {
  width: 280px;
  height: 100vh;
  overflow: scroll;
}
.menuPageContainer {
  width: calc(100% - 280px);
}
.scrollArea {
  height: calc(100vh - 65px);
  overflow: scroll;
}
.dashboardLogo {
  width: 180px;
  height: 65px;
  background: #ccc;
  margin: 0 auto;
  /* margin-top: 10px; */
}

.ant-pro-table-column-setting-list {
  display: flex;
  flex-direction: column;
  /* width: 168px; */
  width: 100%;
}
.ant-pro-table-column-setting-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 26px;
}

.ant-pro-table-column-setting-list-item-option {
  display: none;
  float: right;
  cursor: pointer;
}
.ant-pro-table-column-setting-list-item:hover
  .ant-pro-table-column-setting-list-item-option {
  display: block;
}
.ant-pro-table-column-setting-list-item-option > span + span {
  margin-left: 8px;
}
.ant-layout-sider-trigger {
  background: #16a085;
}
.border-red-500 {
  --border-opacity: 1;
  border-color: #f56565 !important;
}

.ant-modal-body {
  padding: 32px;
}

.ant-modal-close-x {
  width: 32px;
  height: 32px;
  line-height: 32px;
  background-color: white;
}

.ant-upload-list-item-name {
  cursor: pointer;
}

.react-images__footer__caption {
  font-size: 20px;
  /* background-color: #ffffff50; */
  background-color: #00000050;
  padding: 10px;
  border-radius: 3px;
}

.spinner {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.ant-input-number-input-wrap {
  width: 100%;
}

thead > tr > th {
  background-color: #16a085 !important;
  color: white !important;
}

.ant-layout-header {
  z-index: 1060 !important;
  padding-right: 0px;
}

.ant-notification {
  z-index: 1070 !important;
}

.ant-pagination-options {
  display: none;
}

.ant-popover {
  z-index: 1060;
}

.anticon {
  vertical-align: 0em !important;
}

.partnerNavlinkClassName {
  border-bottom: 3px solid #f0b859 !important;
}

.partnerNavlinkClassName > div > h4 {
  color: black !important;
}

.partnerPrimaryNavClassName > img {
  filter: grayscale(0%) !important;
}

.partnerPrimaryNavClassName {
  border-bottom: 3px solid #f0b859 !important;
}

.modal-title-center .ant-modal-title {
  text-align: center;
}

.ant-modal-close {
  display: none;
}

.modal-title-center .ant-modal-footer,
.modal-title-center .ant-modal-header {
  border: none !important;
  text-align: center;
}

@media only screen and (max-width: 1600px) {
  .ant-steps-item-title {
    font-size: 14px;
  }
}
